exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reporting-file-downloads-greenwave-energy-index-js": () => import("./../../../src/pages/reporting-file-downloads/greenwave-energy/index.js" /* webpackChunkName: "component---src-pages-reporting-file-downloads-greenwave-energy-index-js" */),
  "component---src-pages-reporting-file-downloads-index-js": () => import("./../../../src/pages/reporting-file-downloads/index.js" /* webpackChunkName: "component---src-pages-reporting-file-downloads-index-js" */),
  "component---src-pages-select-supplier-js": () => import("./../../../src/pages/select-supplier.js" /* webpackChunkName: "component---src-pages-select-supplier-js" */),
  "component---src-pages-verify-screen-commercial-js": () => import("./../../../src/pages/verifyScreen-Commercial.js" /* webpackChunkName: "component---src-pages-verify-screen-commercial-js" */),
  "component---src-pages-verify-screen-js": () => import("./../../../src/pages/verifyScreen.js" /* webpackChunkName: "component---src-pages-verify-screen-js" */)
}

